$(document).ready(function(){
	//Cookie Notify
	showCookieNotify();
	$("#cookie_notify_button").click(function(e){
		e.preventDefault();
		Cookies.set('cookie_notify_policy_accepted', 1);
		$("#cookie_notify_wrapper").slideUp();
	});

});


//Cookie Notify
function showCookieNotify() {
	if (cn_are_cookies_enabled() && !Cookies.get('cookie_notify_policy_accepted')) {
		$("#cookie_notify_wrapper").delay(1000).slideDown();
	}
}

function cn_are_cookies_enabled()
{
	var cookieEnabled = (navigator.cookieEnabled) ? true : false;
	if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled)
	{
		document.cookie="testcookie";
		cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
	}
	return (cookieEnabled);
}